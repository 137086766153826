import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDHAWAbCzLDh6Ix0eJiUqg8kr3Mu3x0QHk",
  authDomain: "onboarding-kit.firebaseapp.com",
  projectId: "onboarding-kit",
  storageBucket: "onboarding-kit.appspot.com",
  messagingSenderId: "230235189495",
  appId: "1:230235189495:web:72298ed092dc79f9afa3f2",
};

const app = initializeApp(firebaseConfig, "secondary");

export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});

export const storage = getStorage(app);
