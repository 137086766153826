import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../helpers/Firebase";

export default function CollectionPicker({
    library = "",
    setIsLoading = () => { },
    setExercises = () => { }
}) {
    const [collections, setCollections] = useState([])
    const [selectedValue, setSelectedValue] = useState(library)

    useEffect(() => {
        fetchCollections()
    }, [])

    async function fetchCollections() {
        setIsLoading(true);
        await getDocs(collection(db, "fitnesskit/exercises/exercises")).then(
            (querySnapshot) => {
                const data = querySnapshot.docs.map((doc) => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setIsLoading(false);
                setCollections(data);
                const defaultLibrary = data.find(e => e.id === library) || data[0]
                setExercises(defaultLibrary.exercises)
            }
        );
    };

    function handleChangeExerciseLibrary(e) {
        e.preventDefault()
        const value = e.target.value
        setSelectedValue(value)
        const newExercises = collections.find(e => e.id === value)?.exercises
        setExercises(newExercises)
    }


    return (
        <div className="border-b p-4 flex items-center space-x-2">
            <label for="collection">Collection:</label>
            <select name="collection" onChange={e => handleChangeExerciseLibrary(e)} value={selectedValue} defaultValue={library}>
                {
                    collections.map((item, index) =>
                        <option key={item.id} value={item.id}>{item.id}</option>
                    )
                }
            </select>
        </div>
    )
}