import React, { useEffect, useState } from "react";

export default function AudioUploader({ title = "Audio", audio, setAudio }) {
  const [preview, setPreview] = useState(null);
  const types = ["audio/mp3", "audio/mpeg"];

  useEffect(() => {
    if (audio instanceof File) {
      setPreview(URL.createObjectURL(audio));
    } else if (typeof audio === "string") {
      setPreview(audio);
    }
  }, [audio]);

  const handleAudioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && types.includes(file.type)) {
      setAudio(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  return (
    <div className="mb-4">
      <div className="mb-1">{title}</div>
      <div className="flex flex-col w-full border border-2 border-dashed rounded-lg items-center justify-center p-4">
        <label
          className="w-full p-2 flex items-center justify-center cursor-pointer"
          htmlFor="audio-upload"
        >
          {preview ? (
            <span className="text-sm text-gray-600">Audio uploaded</span>
          ) : (
            <span className="opacity-50">Upload Audio</span>
          )}
          <input
            id="audio-upload"
            type="file"
            className="hidden"
            onChange={handleAudioChange}
            accept="audio/mp3,audio/mpeg"
          />
        </label>
        {preview && (
          <div className="mt-4 w-full">
            <audio controls className="w-full">
              <source src={preview} type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
          </div>
        )}
      </div>
    </div>
  );
}
