import Button from "./common/Button";
import DraggableList from "./common/DraggableList";
import React from "react";
import SectionHeader from "./common/SectionHeader";
import SelectableItem from "./common/SelectableItem";
import { secondsToString } from "../helpers/Time";

export default function WorkoutPanel({
  program,
  setProgram,
  selectedSectionIndex,
  selectedWorkoutIndex,
  onSelectSection,
  onSelectWorkout,
  handleAddWorkout,
  handleAddSection,
}) {
  function handleReorderSection(index, newSection) {
    console.log(program);
    console.log(index, newSection);
    const newProgram = [...program];
    newProgram[index].workouts = newSection;
    console.log(newProgram);
    setProgram(newProgram);
  }

  return (
    <div className="flex flex-col h-full w-96 bg-gray-100 border-r border-slate-150">
      <SectionHeader title="Workouts" />
      <div className="mb-3" />

      <div className="flex-1 overflow-y-auto">
        {program && (
          <DraggableList
            items={program}
            setItems={setProgram}
            renderItem={(section, index) => (
              <SectionItem
                key={"section-" + index}
                section={section}
                secIndex={index}
                selectedSectionIndex={selectedSectionIndex}
                selectedWorkoutIndex={selectedWorkoutIndex}
                onSelectSection={onSelectSection}
                onSelectWorkout={onSelectWorkout}
                handleAddWorkout={handleAddWorkout}
                handleReorderSection={(newSection) =>
                  handleReorderSection(index, newSection)
                }
              />
            )}
          />
        )}
      </div>

      <div className="border-t p-2">
        <Button
          title="+ Add a section"
          style="secondary"
          onClick={handleAddSection}
        />
      </div>
    </div>
  );
}

function SectionItem({
  section,
  secIndex,
  selectedSectionIndex,
  selectedWorkoutIndex,
  onSelectSection,
  onSelectWorkout,
  handleAddWorkout,
  handleReorderSection,
}) {
  return (
    <div
      className={`m-2 border rounded-lg overflow-hidden ${selectedSectionIndex === secIndex && "border-indigo-500"
        }`}
    >
      <div
        className={`flex flex-row justify-between p-2 border-b font-medium cursor-pointer ${selectedSectionIndex === secIndex
          ? "bg-primary text-white"
          : "bg-white"
          }`}
        onClick={() => onSelectSection(secIndex)}
      >
        <div>{section.name}</div>
      </div>
      {section && section?.workouts && section?.workouts?.length > 0 && (
        <DraggableList
          items={section?.workouts}
          setItems={handleReorderSection}
          renderItem={(item, wkIndex) => (
            <WorkoutItem
              key={"workout-" + secIndex + wkIndex}
              item={item}
              index={wkIndex}
              isSelected={
                secIndex === selectedSectionIndex &&
                wkIndex === selectedWorkoutIndex
              }
              onSelect={() => onSelectWorkout(secIndex, wkIndex)}
            />
          )}
        />
      )}
      <div className="border-t p-2">
        <Button
          title="+ Add a workout"
          style="secondary"
          onClick={() => handleAddWorkout(secIndex)}
        />
      </div>
    </div>
  );
}

function WorkoutItem({ item, index, isSelected, onSelect }) {
  return (
    <SelectableItem isSelected={isSelected} onSelect={onSelect}>
      <div className="flex flex-row p-1 items-center">
        <div className="flex">
          <div className="h-20 aspect-square bg-gray-100 border rounded mr-2 overflow-hidden">
            {item.image && (
              <img
                src={item.image}
                alt="Workout img"
                className="flex flex-1 aspect-square"
              />
            )}
          </div>
        </div>
        <div className="items-center">
          <div className="flex flex-row">
            {/* <div className="w-5 h-5 flex text-xs font-medium pt-0.5 mr-1 justify-center items-center rounded bg-slate-300/20 border border-slate-400/20">
              {index + 1}
            </div> */}
            <div>{item.name}</div>
          </div>
          <div className="opacity-50 capitalize">
            {item.skills}
            {item.skills && " • "}
            {secondsToString(
              item?.exercises?.reduce((acc, e) => acc + parseInt(e.duration), 0)
            )}
          </div>
        </div>
      </div>
    </SelectableItem>
  );
}
