import React, { useEffect, useState } from "react";

export default function VideoUploader({ title = "Video", video, setVideo }) {
  const [preview, setPreview] = useState(null);

  const types = ["video/mp4"];

  useEffect(() => {
    if (video instanceof File) {
      setPreview(URL.createObjectURL(video));
    } else if (typeof video === "string") {
      setPreview(video);
    }

    // Cleanup function to revoke object URL
    return () => {
      if (preview && preview.startsWith("blob:")) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [video]);

  const handleVideoChange = (e) => {
    const file = e.target.files[0];
    if (file && types.includes(file.type)) {
      setVideo(file);
    }
  };

  return (
    <div className="mb-4">
      <div className="mb-1">{title}</div>
      <div className="flex flex-col w-full border border-2 border-dashed rounded-lg items-center justify-center p-4">
        <label
          className="w-full p-2 flex items-center justify-center cursor-pointer"
          htmlFor="video-upload"
        >
          {preview ? (
            <span className="text-sm text-gray-600">Video uploaded</span>
          ) : (
            <span className="opacity-50">Upload Video</span>
          )}
          <input
            id="video-upload"
            type="file"
            className="hidden"
            onChange={handleVideoChange}
            accept="video/mp4"
          />
        </label>
        {preview && (
          <div className="mt-4 w-full">
            <video controls className="w-full max-h-64 object-contain">
              <source src={preview} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
    </div>
  );
}
