import React from "react";
import SelectableItem from "../common/SelectableItem";

export default function Filter({ title = "", options = [], filter, setFilter }) {
    return (
        <div className="flex flex-row items-center px-3">
            <div className="font-medium">{title}: </div>
            {
                options.map(item => (
                    <SelectableItem
                    customClass={"mx-1 my-0"}
                        isSelected={filter.includes(item)}
                        onSelect={() => setFilter(prev => prev.includes(item) ? prev.filter((e) => e !== item) : [...prev, item])}
                    >
                        <div className="text-xs capitalize px-2 py-1 font-medium">
                            {item}
                        </div>
                    </SelectableItem>
                ))
            }
        </div>
    )
}