import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import FilterSearchBar from "./FilterSearchBar";
import Thumbnail from "../common/Thumbnail";
import CollectionPicker from "./CollectionPicker";
import CreateExercisesModal from "./CreateExerciseModal"; 

import { db } from "../../helpers/Firebase"; // Assuming you have these already set up
import { doc, updateDoc } from "firebase/firestore";

import toast from "react-hot-toast";

export default function ExercisesList({ library, onSelectExercise }) {
  const [isLoading, setIsLoading] = useState()
  const [exercises, setExercises] = useState([])
  const [levels, setLevels] = useState([])
  const [bodyAreas, setBodyAreas] = useState([])
  const [filteredExercises, setFilteredExercises] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);  // State to manage modal visibility
  const [selectedExercise, setSelectedExercise] = useState(null);  // State to hold the selected exercise for editing

  const docRef = doc(db, "fitnesskit/exercises/exercises/", library);

  useEffect(() => {
    setFilteredExercises(exercises)
    setBodyAreas([... new Set(exercises.flatMap(e => e.body))])
    setLevels([... new Set(exercises.flatMap(e => e.level))])
  }, [exercises])

  function handleEditExercise(exercise) {
    setSelectedExercise(exercise);  // Set the exercise to be edited
    setIsModalOpen(true);  // Open the modal
  }

  async function handleDeleteExercise(exerciseToDelete) {
    // Display a confirmation dialog
    if (window.confirm("Are you sure you want to delete this exercise?")) {
        const updatedExercises = exercises.filter(exercise => exercise.id !== exerciseToDelete.id);
        setExercises(updatedExercises);
        setFilteredExercises(updatedExercises); // Simplifying the update of filtered exercises

        // Using toast.promise to handle the async operation feedback
        toast.promise(
            updateDoc(docRef, { exercises: updatedExercises }),
            {
                loading: "Deleting...",
                success: <b>Exercise Deleted</b>,
                error: <b>Could not delete exercise</b>,
            }
        );
    }
}

  return (
    <div className="h-full bg-white">
      <CollectionPicker
        library={library}
        setIsLoading={setIsLoading}
        setExercises={setExercises} />
      <FilterSearchBar
        items={exercises}
        setFilteredItems={setFilteredExercises}
        levels={levels}
        body_areas={bodyAreas}
      />
      <CreateExercisesModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        library={library}
        exercisesLibrary={exercises}
        existingExercise={selectedExercise}
      />
      <div className="h-full bg-gray-100 py-4 px-8 pb-36 overflow-y-scroll">
        <div className="mb-2 text-center">
          <span className="font-medium">{filteredExercises.length}</span>{" "}
          exercise{filteredExercises.length > 0 && "s"}
        </div>
        <div className="w-full bg-white shadow rounded-lg overflow-hidden divide-y">
          {filteredExercises.map((e) => (
            <div className="w-full flex flex-row px-2 py-2 items-center justify-between bg-white hover:bg-slate-200/20">
              <div className="flex items-center">
                <div className="flex h-20 aspect-square mr-4 bg-slate-200 rounded-md items-center justify-center overflow-hidden">
                  <Thumbnail src={e?.thumbnail} />
                </div>
                <div>
                  <div className="font-medium mb-2">{e.name}</div>
                  <div className="flex flex-row space-x-2">
                    <div className="px-2 py-0.5 text-xs rounded bg-slate-300/20 border border-slate-400/20">
                      {e.level}
                    </div>
                    <div className="flex flex-row space-x-1">
                      {
                        (Array.isArray(e.body) ? e.body : [e.body]).map(body =>
                          <div className="px-2 py-0.5 text-xs rounded bg-slate-300/20 border border-slate-400/20">
                            {body}
                          </div>)
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex">
                <Button title="Select" onClick={() => onSelectExercise(e.id)} />
                <Button title="Edit" onClick={() => handleEditExercise(e)} />
                <Button title="Delete" onClick={() => handleDeleteExercise(e)} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
