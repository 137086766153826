import React, { useEffect, useState } from "react";
import TopBar from "../components/home/TopBar";
import { Timestamp, addDoc, collection, doc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../helpers/Firebase";
import SelectableItem from "../components/common/SelectableItem";
import { useNavigate } from "react-router-dom";
import Loading from "../components/common/Loading";
import { exercises_library } from "../data/exercises";

export default function HomePage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [programs, setPrograms] = useState([
    { name: "Football" },
    { name: "Basketball" },
  ]);

  useEffect(() => {
    fecthPrograms();
  }, []);

  const fecthPrograms = async () => {
    setIsLoading(true);
    await getDocs(collection(db, "fitnesskit/programs/programs/")).then(
      (querySnapshot) => {
        const data = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setIsLoading(false);
        setPrograms(data);
        console.log(data);
      }
    );
  };

  function handleOpenProgram(id) {
    navigate("/create/" + id);
  }

  function handleCreateProgram() {
    addDoc(collection(db, "fitnesskit/programs/programs"), {
      name: "New program",
      sections: [],
      updated: Timestamp.now(),
    })
      .then((doc) => handleOpenProgram(doc.id))
  }

  return (
    <div className="w-screen h-full">
      <TopBar
        handleCreateProgram={handleCreateProgram} />
      <div className="h-[calc(100vh-48px)] flex flex-row">
        {isLoading ? (
          <LoadingView />
        ) : (
          <div className="flex-1 p-4 bg-gray-100 ">
            <div className="grid grid-cols-6 gap-2">
              {programs &&
                programs.map((item) => (
                  <SelectableItem onSelect={() => handleOpenProgram(item.id)}>
                    <div className="flex p-4 text-lg font-medium rounded-lg cursor-pointer">
                      {item.name}
                    </div>
                  </SelectableItem>
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function LoadingView() {
  return (
    <div className="flex flex-1 justify-center items-center">
      <Loading />
    </div>
  );
}
