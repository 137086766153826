import React, { useState } from 'react';

function DraggableList({ items, setItems, renderItem = () => { } }) {
    const [draggingIndex, setDraggingIndex] = useState(null);

    const onDragStart = (e, index) => {
        setDraggingIndex(index);
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', '');
    };

    const onDragOver = (e, index) => {
        e.preventDefault();
        if (draggingIndex === null || index === draggingIndex) {
            return;
        }

        let newItems = [...items];
        const draggedItem = newItems[draggingIndex];
        newItems.splice(draggingIndex, 1);
        newItems.splice(index, 0, draggedItem);

        setItems(newItems);
        setDraggingIndex(index);
    };

    const onDragEnd = () => {
        setDraggingIndex(null);
    };

    if (!items) return

    return (
        <ul>
            {items.map((item, index) => (
                <li
                    key={index}
                    draggable
                    onDragStart={e => onDragStart(e, index)}
                    onDragOver={e => onDragOver(e, index)}
                    onDragEnd={onDragEnd}
                    style={{
                        opacity: draggingIndex === index ? 0.5 : 1,
                    }}
                >
                    {renderItem(item, index)}
                </li>
            ))}
        </ul>
    );
}

export default DraggableList;