export function secondsToString(duration) {
    // Hours, minutes and seconds
    const hrs = ~~(duration / 3600);
    const mins = ~~((duration % 3600) / 60);
    const secs = ~~duration % 60;

    let ret = "";

    ret += hrs > 0 ? hrs + " h " : ""
    ret += mins > 0 ? mins + " min " : ""
    ret += secs > 0 ? secs + " sec": ""

    return ret;
}