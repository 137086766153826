import React, { useState } from "react";
import ExercisesModal from "../exercises.js/ExercisesModal";
import WorkoutPanel from "../WorkoutPanel";
import ExercisePanel from "../ExercisePanel";
import SettingsPanel from "../SettingsPanel";
import DeleteConfirmationModal from "../common/DeleteConfirmationModal";
import { storage } from "../../helpers/Firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import toast from "react-hot-toast";
import Loading from "../common/Loading";

export default function CreateLayout({ isLoading, program, setProgram, library, exercisesLibrary }) {
  const [isExercisesModalOpen, setIsExercisesModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);
  const [selectedWorkoutIndex, setSelectedWorkoutIndex] = useState(null);
  const [selectedExerciseIndex, setSelectedExerciseIndex] = useState(null);
  const selectedSection = (program && program[selectedSectionIndex]) || null;
  const selectedWorkout =
    (selectedSection && selectedSection?.workouts[selectedWorkoutIndex]) ||
    null;
  const selectedExercise =
    (selectedWorkout && selectedWorkout?.exercises[selectedExerciseIndex]) ||
    null;

  // SELECT
  function handleSelectSection(index) {
    setSelectedExerciseIndex(null);
    setSelectedWorkoutIndex(null);
    setSelectedSectionIndex(index);
  }

  function handleSelectWorkout(secIndex, wkIndex) {
    setSelectedExerciseIndex(null);
    setSelectedSectionIndex(secIndex);
    setSelectedWorkoutIndex(wkIndex);
  }

  // ADD
  function handleAddSection() {
    setProgram([
      ...program,
      { name: "Untitled", type: "section", workouts: [] },
    ]);
    handleSelectSection(program.length);
  }

  function handleAddWorkout(sectionIndex = selectedSectionIndex) {
    if (program[sectionIndex] != null) {
      const newProgram = [...program];
      newProgram[sectionIndex].workouts.push({
        name: "New workout",
        type: "workout",
        exercises: [],
      });
      setProgram(newProgram);
      handleSelectWorkout(
        sectionIndex,
        program[sectionIndex].workouts.length - 1
      );
    }
  }

  function handleAddExercise(type = "exercise") {
    if (selectedSection != null && selectedWorkout != null) {
      const newExercise = { type: type, id: "undefined", duration: 30 };
      const newProgram = [...program];
      newProgram[selectedSectionIndex].workouts[
        selectedWorkoutIndex
      ].exercises.push({ ...newExercise });
      setProgram(newProgram);
      setSelectedExerciseIndex(
        newProgram[selectedSectionIndex].workouts[selectedWorkoutIndex]
          .exercises.length - 1
      );
    }
  }

  // UPDATE
  function handleUpdateSection(property, value) {
    if (selectedSection != null) {
      const newProgram = [...program];
      newProgram[selectedSectionIndex] = {
        ...program[selectedSectionIndex],
        [property]: value,
      };
      setProgram(newProgram);
    }
  }

  function handleUpdateWorkout(property, value) {
    if (selectedSection != null && selectedWorkout != null) {
      const newWorkout = {
        ...program[selectedSectionIndex].workouts[selectedWorkoutIndex],
        [property]: value,
      };
      const newProgram = [...program];
      newProgram[selectedSectionIndex].workouts[selectedWorkoutIndex] =
        newWorkout;
      setProgram(newProgram);
    }
  }

  function handleUpdateExercise(property, value) {
    if (
      selectedSection != null &&
      selectedWorkout != null &&
      selectedExercise != null
    ) {
      const newExercise = {
        ...program[selectedSectionIndex].workouts[selectedWorkoutIndex]
          .exercises[selectedExerciseIndex],
        [property]: value,
      };
      const newProgram = [...program];
      newProgram[selectedSectionIndex].workouts[selectedWorkoutIndex].exercises[
        selectedExerciseIndex
      ] = newExercise;
      setProgram(newProgram);
    }
  }

  function handleAddImage(image) {
    if (image) {
      const imageRef = ref(storage, `workouts/images/${image.name}`);
      toast.promise(
        uploadBytes(imageRef, image).then((snapshot) => {
          getDownloadURL(snapshot.ref).then((url) => {
            handleUpdateWorkout("image", url);
          });
        }),
        {
          loading: "Uploading image...",
          success: <b>Image uploaded!</b>,
          error: <b>Could not upload the image.</b>,
        }
      );
    }
  }

  // DELETE
  function handleDeleteSection() {
    if (selectedSection != null) {
      setProgram(program.filter((_, index) => index !== selectedSectionIndex));
    }
  }
  function handleDeleteWorkout() {
    if (selectedSection != null && selectedWorkout != null) {
      const newProgram = [...program];
      newProgram[selectedSectionIndex].workouts = newProgram[
        selectedSectionIndex
      ].workouts.filter((_, index) => index !== selectedWorkoutIndex);
      setProgram(newProgram);
    }
  }

  function handleDeleteExercise() {
    if (
      selectedSection != null &&
      selectedWorkout != null &&
      selectedExercise != null
    ) {
      const newProgram = [...program];
      newProgram[selectedSectionIndex].workouts[
        selectedWorkoutIndex
      ].exercises = newProgram[selectedSectionIndex].workouts[
        selectedWorkoutIndex
      ].exercises.filter((_, index) => index !== selectedExerciseIndex);
      setProgram(newProgram);
    }
  }

  function handleDeleteSelectedItem() {
    if (selectedExercise !== null) {
      handleDeleteExercise();
    } else if (selectedWorkout !== null) {
      handleDeleteWorkout();
    } else if (selectedSection !== null) {
      handleDeleteSection();
    }
  }

  if (isLoading) {
    return (
      <div className="flex flex-1 justify-center items-center">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <ExercisesModal
        isOpen={isExercisesModalOpen}
        handleClose={() => setIsExercisesModalOpen(false)}
        onSelectExercise={(id) => handleUpdateExercise("id", id)}
        library={library}
      />
      <DeleteConfirmationModal
        isOpen={isConfirmationModalOpen}
        handleClose={() => setIsConfirmationModalOpen(false)}
        handleConfirm={handleDeleteSelectedItem}
      />
      <WorkoutPanel
        program={program}
        setProgram={setProgram}
        selectedSectionIndex={selectedSectionIndex}
        setSelectedSectionIndex={setSelectedSectionIndex}
        selectedWorkoutIndex={selectedWorkoutIndex}
        onSelectSection={handleSelectSection}
        onSelectWorkout={handleSelectWorkout}
        handleAddWorkout={handleAddWorkout}
        handleAddSection={handleAddSection}
      />
      <ExercisePanel
        exercises={selectedWorkout?.exercises}
        setExercises={(ex) => handleUpdateWorkout("exercises", ex)}
        selectedExerciseIndex={selectedExerciseIndex}
        onSelectExercise={setSelectedExerciseIndex}
        handleAddExercise={() => handleAddExercise("exercise")}
        handleAddRest={() => handleAddExercise("rest")}
        library={library}
        exercisesLibrary={exercisesLibrary}
      />
      <SettingsPanel
        exercisesLibrary={exercisesLibrary}
        itemToEdit={selectedExercise || selectedWorkout || selectedSection}
        handleUpdateSection={handleUpdateSection}
        handleUpdateWorkout={handleUpdateWorkout}
        handleUpdateExercise={handleUpdateExercise}
        handleDeleteSelectedItem={() => setIsConfirmationModalOpen(true)}
        handleOpenExercisesModal={() => setIsExercisesModalOpen(true)}
        handleSetImage={handleAddImage}
      />
    </>
  );
}
