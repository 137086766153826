import React from "react";

export default function SegmentedControl({ value = "exercise", onSelect, options = ["exercise", "rest"] }) {
    return (
        <div className="flex mb-4 cursor-pointer divide-x divide-slate-400/20 overflow-hidden rounded-md bg-white capitalize font-medium text-slate-700 shadow-sm ring-1 ring-slate-700/10">
            {
                options.map(option => (
                    <div
                        class={`flex flex-1 ${value === option ? "bg-primary text-white" : "bg-white"} justify-center px-4 py-2 hover:bg-primary hover:text-white`}
                        onClick={() => onSelect(option)}>
                        {option}
                    </div>
                ))
            }
        </div>
    )
}