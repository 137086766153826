import React, { useEffect } from "react";
import useComponentVisible from "../../hooks/useComponentVisible";
import ModalView from "../layouts/ModalView";
import ExercisesList from "./ExercisesList";

export default function ExercisesModal({ isOpen, handleClose, onSelectExercise, library }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    useEffect(() => {
        if (isOpen) {
            setIsComponentVisible(true)
        }
        if (isComponentVisible === false) {
            handleClose()
        }
    }, [isOpen, isComponentVisible])

    function handleSelectExercise(id) {
        setIsComponentVisible(false)
        onSelectExercise(id)
    }

    return (
        <ModalView isOpen={isComponentVisible}>
            <div ref={ref} className="flex flex-col m-8 h-[600px] w-[900px] shadow rounded">
                <div className="h-full rounded-lg overflow-hidden">
                    <ExercisesList 
                        library={library} 
                        onSelectExercise={handleSelectExercise} 
                    />
                </div>
            </div>
        </ModalView>
    )
}