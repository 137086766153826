import React from "react";

export default function TextInput({ value, title, unit = "", placeholder, type = "text", onChange }) {
    return (
        <div>
            <div className="mb-1">{title}</div>
            <label className="relative">
                <input
                    className="mb-4 placeholder:italic placeholder:text-slate-400 bg-white w-full border border-slate-300 rounded-md py-2 px-3 focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    type={type} />
                <span className="absolute h-full top-0 bottom-0 right-4 text-right opacity-50">{unit}</span>
            </label>
        </div>
    )
}