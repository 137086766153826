import Button from "../common/Button";
import KovaleeLogo from "../common/KovaleeLogo";
import React from "react";
import TopBarContainer from "../layouts/TopBarContainer";

export default function TopBar({ handleCreateProgram = () => {} }) {
  return (
    <TopBarContainer>
      <div className="flex justify-between items-center flex-1">
        <KovaleeLogo className="w-28 fill-current text-black" />
        <div className="font-medium text-lg">Fitness Configurator</div>
        <div className="flex flex-row items-center spac-x-2">
          <Button onClick={handleCreateProgram} title="New" />
        </div>
      </div>
    </TopBarContainer>
  );
}
