import React, { useState, useEffect, useRef } from 'react';

export default function Dropdown({ title, options, selectedOptions, setSelectedOptions, multiselect = false }) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        if (multiselect) {
            const newOptions = selectedOptions ?
                selectedOptions.includes(option) ?
                    selectedOptions.filter(e => e !== option)
                    : [...selectedOptions, option] : [option]
            setSelectedOptions(newOptions);
        } else {
            setSelectedOptions(option)
            setIsOpen(false)
        }
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div>
            <div className="mb-1">{title}</div>
            <div className={`relative mb-4 ${isOpen ? "z-50" : "z-10"}`} ref={dropdownRef}>
                <button onClick={toggleDropdown} className={`w-full flex flex-row justify-between items-center py-2 px-3 border rounded-lg text-left ${selectedOptions && selectedOptions.length > 0 ? "text-black capitalize" : "text-slate-400"}`}>
                    {multiselect ?
                        selectedOptions && selectedOptions.length > 0 ? selectedOptions.join(", ") : "Select option(s)"
                        : selectedOptions || "Select option"}
                    <Chevron isUp={isOpen} />
                </button>
                {console.log(title, selectedOptions)}
                {isOpen && (
                    <ul className="absolute left-0 right-0 bg-white border mt-1 rounded-lg overflow-hidden shadow">
                        {options.map((option, index) => (
                            <li
                                key={index}
                                onClick={() => handleOptionClick(option)}
                                className={`px-4 py-2 flex items-center hover:bg-gray-50 cursor-pointer capitalize ${!multiselect && option === selectedOptions && "bg-gray-100 hover:bg-gray-200"}`}
                            >
                                {
                                    multiselect &&
                                    <input
                                        type="checkbox"
                                        checked={selectedOptions && selectedOptions.includes(option)}
                                        class="w-4 h-4 mr-2 accent-indigo-500"
                                    />
                                }

                                {option}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

function Chevron({ isUp = false }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d={isUp ? "M4.5 15.75l7.5-7.5 7.5 7.5" : "M19.5 8.25l-7.5 7.5-7.5-7.5"} />
        </svg>
    )
}