import React, { useState, useEffect } from "react";
import { db } from "../helpers/Firebase";
import { Timestamp, doc, getDoc, updateDoc } from "firebase/firestore";
import toast, { Toaster } from "react-hot-toast";
import CreateLayout from "../components/layouts/CreateLayout";
import TopBar from "../components/create/TopBar";
import { useNavigate, useParams } from "react-router-dom";

export default function CreatePage() {
  const params = useParams();
  const navigate = useNavigate();
  const docRef = doc(db, "fitnesskit/programs/programs", params?.programId);
  const [isLoading, setIsLoading] = useState(false);
  const [program, setProgram] = useState(null);
  const [firebaseProgram, setFirebaseProgram] = useState(null);
  const isPublished = program === firebaseProgram;

  const [exercisesLibrary, setExercisesLibrary] = useState()
  const [isLibraryLoading, setIsLibraryLoading] = useState(true)

  useEffect(() => {
    fecthWorkouts();
  }, []);

  useEffect(() => {
    if (program && !exercisesLibrary) {
      fetchExercisesLibrary()
    }
  }, [program])

  async function fecthWorkouts() {
    setIsLoading(true);
    await getDoc(docRef).then((docSnap) => {
      setIsLoading(false);
      if (docSnap.exists()) {
        const doc = docSnap.data();
        setProgram(doc);
        setFirebaseProgram(doc);
      }
    });
  }

  async function fetchExercisesLibrary() {
    const library = program.library || "fitness"
    await getDoc(doc(db, "fitnesskit/exercises/exercises", library)).then(
      (docSnap) => {
        const data = docSnap.data()
        setExercisesLibrary(data.exercises);
        setIsLibraryLoading(false)
      }
    );
  };

  

  function handleBack() {
    navigate("/");
  }

  function updateProgram(newSection) {
    setProgram({ ...program, sections: newSection });
  }

  async function handleSave() {
    toast.promise(
      updateDoc(docRef, {
        sections: program?.sections,
        updated: Timestamp.now(),
      }).then(() => setFirebaseProgram(program)),
      {
        loading: "Saving...",
        success: <b>Program saved!</b>,
        error: <b>Could not save</b>,
      }
    );
  }

  const handleDownload = () => {
    const content = program?.sections;
    const dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify({ content }));
    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "fitness_config.json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const handleDownloadExercisesLibrary = () => {
    const dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify({ exercisesLibrary }));

    const downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "exercises_library.json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  return (
    <div className="w-screen h-full">
      <Toaster />
      <TopBar
        title={program?.name}
        isPublished={isPublished}
        handleBack={handleBack}
        handleSave={handleSave}
        handleDownload={handleDownload}
        handleDownloadExercisesLibrary={handleDownloadExercisesLibrary}
      />
      <div className="h-[calc(100vh-48px)] flex flex-row">
        <CreateLayout
          isLoading={isLoading}
          program={program?.sections}
          setProgram={updateProgram}
          library={program?.library || 'fitness'}
          exercisesLibrary={exercisesLibrary}
        />
      </div>
    </div>
  );
}
