import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CreatePage from "../pages/CreatePage";
import HomePage from "../pages/HomePage";

export default function Root() {
  return (
    <Router>
      <Routes>
        <Route path="/create/:programId" element={<CreatePage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </Router>
  );
}
