import React from "react";
import Button from "../common/Button";
import VideoPlayer from "../common/VideoPlayer";

export default function ExerciseSelector({
  exercise,
  handleOpenExercisesModal,
}) {
  return (
    <div className="my-4">
      <div className="mb-1">Exercise</div>
      <div className="p-3 border rounded-lg">
        {exercise && (
          <div>
            <div className="flex mb-2 aspect-square rounded-md bg-slate-200 items-center justify-center overflow-hidden z-0">
              <VideoPlayer src={exercise?.video} autoPlay loop />
            </div>

            <div class="group flex relative mb-1">
              <div className="font-medium">{exercise?.name}</div>
              <span class="text-slate-500 ml-2">ⓘ</span>
              <div class="absolute bottom-0 mb-6 group-hover:opacity-100 opacity-0 transition-opacity ">
                <div class="bg-white border text-sm rounded py-2 px-3">
                  <div className="font-semibold mb-1">Instructions</div>
                  {exercise?.instructions}
                </div>
              </div>
            </div>

            <div className="inline-flex my-1 mb-3">
              <div className="px-2 py-0.5 mr-2 text-xs rounded bg-slate-300/20 border border-slate-400/20">
                {exercise?.level}
              </div>
              <div className="inline-flex space-x-1">
                {
                  (Array.isArray(exercise.body) ? exercise.body : [exercise.body]).map(body =>
                    <div className="px-2 py-0.5 text-xs rounded bg-slate-300/20 border border-slate-400/20">
                      {body}
                    </div>)
                }
              </div>
            </div>
          </div>
        )}
        <Button
          title={exercise ? "Change exercise" : "+ Add an exercise"}
          style="secondary"
          onClick={handleOpenExercisesModal}
        />
      </div>
    </div>
  );
}
