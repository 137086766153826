import React from "react";
import Spinner from "./Spinner";

export default function Loading() {
  return (
    <div class="inline-flex items-center px-4 py-2 font-semibold text-sm shadow rounded-lg text-white bg-primary">
      <Spinner customClass="-ml-1 mr-3" />
      Loading...
    </div>
  );
}
