import React from "react";
import TopBarContainer from "../layouts/TopBarContainer";
import Button from "../common/Button";

export default function TopBar({
  title = "Fitness Configurator",
  isPublished = true,
  handleBack,
  handleSave,
  handleDownload,
  handleDownloadExercisesLibrary,
}) {
  return (
    <TopBarContainer>
      <div className="flex flex-row w-full items-center justify-between">
        <div className="flex flex-row items-center">
          <div
            className="aspect-square flex justify-center items-center border-r h-full w-12 mr-4 cursor-pointer hover:bg-gray-200/20"
            onClick={handleBack}
          >
            ←
          </div>
          <div className="font-medium text-lg">{title}</div>
        </div>
        <div className="flex items-center space-x-2">
          {!isPublished && (
            <div className="mr-4 italic opacity-50 text-xs">Unpublished</div>
          )}
          <Button
            onClick={handleSave}
            title={
              <span className="flex flex-row items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4 mr-1"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zm9.586 4.594a.75.75 0 00-1.172-.938l-2.476 3.096-.908-.907a.75.75 0 00-1.06 1.06l1.5 1.5a.75.75 0 001.116-.062l3-3.75z"
                    clipRule="evenodd"
                  />
                </svg>
                Save
              </span>
            }
          />
          <Button
            onClick={handleDownload}
            title={
              <span className="flex flex-row items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-4 h-4 mr-1"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2.25a.75.75 0 01.75.75v11.69l3.22-3.22a.75.75 0 111.06 1.06l-4.5 4.5a.75.75 0 01-1.06 0l-4.5-4.5a.75.75 0 111.06-1.06l3.22 3.22V3a.75.75 0 01.75-.75zm-9 13.5a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                    clipRule="evenodd"
                  />
                </svg>
                Download
              </span>
            }
          />

          <Button
            onClick={handleDownloadExercisesLibrary}
            title={
              <span className="items-center w-64">
                Download Exercises Library
              </span>
            }
          />
        </div>
      </div>
    </TopBarContainer>
  );
}
