import React, { useEffect, useState } from "react";
import useComponentVisible from "../../hooks/useComponentVisible";
import ModalView from "../layouts/ModalView";
import TextInput from "../settings/TextInput";
import ImageUploader from "../settings/ImageUploader";
import VideoUploader from "../common/VideoUploader";
import AudioUploader from "../common/AudioUploader";
import Button from "../common/Button";
import { storage, db } from "../../helpers/Firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";

import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';

export default function CreateExercisesModal({ isOpen, handleClose, library, exercisesLibrary, existingExercise }) {
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [exercise, setExercise] = useState({
        name: '',
        id: uuidv4(),
        instructions: '',
        body: [],
        thumbnail: '',
        video: '',
        ...existingExercise
    });
    const docRef = doc(db, "fitnesskit/exercises/exercises/", library);

    useEffect(() => {
        if (isOpen) {
            setIsComponentVisible(true);
        }
        if (!isComponentVisible) {
            handleClose();
        }
    }, [isOpen, isComponentVisible]);

    useEffect(() => {
        // When existingExercise is updated, reinitialize the exercise state
        setExercise({
            name: '',
            id: uuidv4(),
            instructions: '',
            body: [],
            thumbnail: '',
            video: '',
            ...existingExercise
        });
    }, [existingExercise]);

    function handleUpdateExercise(property, value) {
        setExercise((prevExercise) => ({
            ...prevExercise,
            [property]: value,
        }));
    }

    function handleAddImage(thumbnail) {
        if (thumbnail) {
            const imageRef = ref(storage, `exercises/images/${thumbnail.name}`);
            toast.promise(
                uploadBytes(imageRef, thumbnail).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((url) => {
                        handleUpdateExercise("thumbnail", url);
                    });
                }),
                {
                    loading: "Uploading thumbnail...",
                    success: <b>Image uploaded!</b>,
                    error: <b>Could not upload the image.</b>,
                }
            );
        }
    }

    async function onCreateNewExercise() {
        exercisesLibrary.push(exercise);
        console.log(exercisesLibrary);
        await handleSave();
        setIsComponentVisible(false);
    }

    async function handleSaveOrUpdateExercise() {
        const index = exercisesLibrary.findIndex(ex => ex.id === exercise.id);
        if (index > -1) {
            exercisesLibrary[index] = exercise; // Update existing exercise
        } else {
            exercisesLibrary.push(exercise); // Add new exercise
        }
        console.log(exercisesLibrary);
        await handleSave();
        setIsComponentVisible(false);
    }

    async function handleSave() {
        toast.promise(
            updateDoc(docRef, { exercises: exercisesLibrary }),
            {
                loading: "Saving...",
                success: <b>Exercise saved!</b>,
                error: <b>Could not save</b>,
            }
        );
    }

    function handleAddVideo(video) {
        if (video) {
            const videoRef = ref(storage, `exercises/videos/${video.name}`);
            toast.promise(
                uploadBytes(videoRef, video).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((url) => {
                        handleUpdateExercise("video", url);
                    });
                }),
                {
                    loading: "Uploading video...",
                    success: <b>Video uploaded!</b>,
                    error: <b>Could not upload the video.</b>,
                }
            );
        }
    }

    function handleAddAudio(audio) {
        if (audio) {
            const audioRef = ref(storage, `exercises/audios/${audio.name}`);
            toast.promise(
                uploadBytes(audioRef, audio).then((snapshot) => {
                    getDownloadURL(snapshot.ref).then((url) => {
                        handleUpdateExercise("audio", url);
                    });
                }),
                {
                    loading: "Uploading audio...",
                    success: <b>Audio uploaded!</b>,
                    error: <b>Could not upload the audio.</b>,
                }
            );
        }
    }

    function handleBodyPartChange(value: string) {
        const bodyArray = value
            .split(',')
            .map(body => body.trim())
            .filter(body => body !== ''); // Remove empty strings
    
        // Ensure bodyArray is always an array, even if empty
        const safeBodyArray = Array.isArray(bodyArray) ? bodyArray : [];
        handleUpdateExercise('body', safeBodyArray);
    }

    return (
        <ModalView isOpen={isComponentVisible}>
            <div ref={ref} className="flex flex-col m-8 w-[1000px] shadow rounded">
                <div className="h-full rounded-lg overflow-hidden">
                    <div className="h-full bg-white p-8">
                        <div className="space-y-4 mb-6">
                            <TextInput
                                title={"Name"}
                                placeholder={"Exercise name..."}
                                value={exercise.name}
                                onChange={(e) =>
                                    handleUpdateExercise("name", e.target.value)
                                }
                            />
                            <TextInput
                                title={"Instructions"}
                                placeholder={"Exercise instructions..."}
                                value={exercise.instructions}
                                onChange={(e) =>
                                    handleUpdateExercise("instructions", e.target.value)
                                }
                            />
                            <TextInput
                                title="Body Part"
                                placeholder={"Body parts, comma separated..."}
                                value={Array.isArray(exercise.body) ? exercise.body.join(', ') : ''}
                                onChange={(e) => handleBodyPartChange(e.target.value)}
                            />
                        </div>
                        
                        <div className="mb-6">
                            <h3 className="text-lg font-semibold mb-2">Assets</h3>
                            <div className="flex space-x-4">
                                <div className="flex-1">
                                    <ImageUploader
                                        image={exercise?.thumbnail}
                                        setImage={handleAddImage}
                                    />
                                </div>
                                <div className="flex-1">
                                    <VideoUploader
                                        video={exercise?.video}
                                        setVideo={handleAddVideo}
                                    />
                                </div>
                                <div className="flex-1">
                                    <AudioUploader
                                        audio={exercise?.audio}
                                        setAudio={handleAddAudio}
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex justify-end space-x-4">
                            <Button title="Save" onClick={handleSaveOrUpdateExercise} />
                            <Button title="Close" onClick={() => setIsComponentVisible(false)} />
                        </div>
                    </div>
                </div>
            </div>
        </ModalView>
    )
}