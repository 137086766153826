import React from "react";

export default function SectionHeader({ title, ...props }) {
    return (
        <div className="flex h-14 bg-white items-center justify-between px-3 border-b border-slate-150 shadow-sm">
            <div className="text-base font-medium">{title}</div>
            <div>
                {props.children}
            </div>
        </div>
    )
}