import React, { useEffect } from "react";
import useComponentVisible from "../../hooks/useComponentVisible";
import ModalView from "../layouts/ModalView";
import Button from "./Button";

export default function DeleteConfirmationModal({ isOpen, handleClose, type = "element", handleConfirm }) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    useEffect(() => {
        if (isOpen) {
            setIsComponentVisible(true)
        }
        if (isComponentVisible === false) {
            handleClose()
        }
    }, [isOpen, isComponentVisible])

    function onConfirm() {
        setIsComponentVisible(false)
        handleConfirm()
    }

    return (
        <ModalView isOpen={isComponentVisible}>
            <div ref={ref} className="flex flex-col p-4 w-80 bg-white shadow rounded">
                <div>
                    <div className="text-lg font-medium mb-1">Confirm deletion?</div>
                    <div className="opacity-50">Are you sure you would like to delete this {type} from your program?</div>
                </div>
                <div className="mt-4">
                    <Button onClick={() => setIsComponentVisible(false)} title="Cancel" />
                    <div className="mb-2" />
                    <Button onClick={onConfirm} style="danger" title="Delete" />
                </div>
            </div>
        </ModalView>
    )
}