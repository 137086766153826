import { useEffect } from 'react';
import './App.css';
import Root from './navigation/Root';
import { getDownloadURL, getMetadata, listAll, ref } from 'firebase/storage';
import { db, storage } from './helpers/Firebase';
import { doc, getDoc } from 'firebase/firestore';

export default function App() {

  useEffect(() => {
    fetchExercises()
  }, [])

  async function fetchExercises() {
    const docRef = doc(db, "fitnesskit/exercises/exercises", "pilates");
    await getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const doc = docSnap.data();
        console.log(doc)
      }
    });
  }



  const fetchVideos = async () => {
    listAll(ref(storage, "fitness/exercises/pilates/videos/256")).then(async (res) => {
      const { items } = res;
      const urls = await Promise.all(
        items.map((item) => getDownloadURL(item))
      );

      // Array of download URLs of all files in that folder
      console.log(urls);
    })
  }


  return (
    <div className="h-screen text-sm">
      <Root />
    </div>
  );
}