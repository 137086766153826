import React from "react";

export default function Button({ title, onClick, style = "standard" }) {
  const customClass = {
    standard: "bg-slate-50/20 border hover:bg-slate-100",
    danger: "bg-red-500 text-white hover:bg-red-400",
    secondary:
      "border border-dashed font-normal border-primary text-primary  hover:opacity-80",
    secondary_danger:
      "border border-dashed font-normal border-red-600 text-red-600 opacity-30 hover:opacity-100",
  };

  return (
    <div
      className={`flex-1 flex flex-row justify-center items-center cursor-pointer rounded-md px-3 font-medium py-2 text-center ${customClass[style]}`}
      onClick={onClick}
    >
      {title}
    </div>
  );
}
