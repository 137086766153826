import React, { useState } from "react";
import SelectableItem from "./common/SelectableItem";
import SectionHeader from "./common/SectionHeader";
import DraggableList from "./common/DraggableList";
import { secondsToString } from "../helpers/Time";
import CreateExerciseModal from "./exercises.js/CreateExerciseModal";

import Button from "./common/Button";
import Thumbnail from "./common/Thumbnail";

export default function ExercisePanel({
  library,
  exercisesLibrary,
  exercises,
  setExercises,
  selectedExerciseIndex,
  onSelectExercise,
  handleAddExercise,
  handleAddRest,
}) {
  const exercisesCount = exercises?.filter((e) => e.type === "exercise").length;
  const workoutDurationSec = exercises?.reduce(
    (acc, e) => acc + parseInt(e.duration),
    0
  );
  const workoutDurationString = secondsToString(workoutDurationSec);
  const [isCreateExercisesModalOpen, setIsCreateExercisesModalOpen] = useState(false);

  return (
    <div className="flex flex-1 flex-col h-full bg-gray-100">
      <SectionHeader title="Exercises">
        {exercises && (                  
          <div className="opacity-30">
            {exercisesCount} exercise{exercisesCount > 1 && "s"} |{" "}
            {workoutDurationString}
          </div>
        )}
      </SectionHeader>
      <CreateExerciseModal
          isOpen={isCreateExercisesModalOpen}
          handleClose={() => setIsCreateExercisesModalOpen(false)}
          library={library}
          exercisesLibrary={exercisesLibrary}
          />
      {exercises && exercisesLibrary ? (
        <>
          <div className="flex flex-col flex-1 overflow-y-auto py-3">
            <DraggableList
              items={exercises}
              setItems={setExercises}
              renderItem={(exercise, index) => {
                const ex = exercisesLibrary ? exercisesLibrary.find((e) => e?.id === exercise.id) : exercise
                return (
                  <div>
                    <SelectableItem
                      isSelected={selectedExerciseIndex === index}
                      onSelect={() => onSelectExercise(index)}
                    >
                      <div className={`flex flex-row p-2 items-center`}>
                        {exercise.type === "exercise" ? (
                          <>
                            <div className="flex mr-4 h-20 aspect-square rounded-md bg-slate-200 items-center justify-center overflow-hidden">
                              <Thumbnail src={ex?.thumbnail} />
                            </div>
                            <div>
                              <div className="font-medium">
                                {ex?.name || exercise.id}
                              </div>
                              {ex && (
                                <div className="flex flex-row items-center my-1">
                                  <div className="px-2 py-0.5 mr-2 text-xs rounded bg-slate-300/20 border border-slate-400/20">
                                    {ex?.level}
                                  </div>
                                  <div className="flex flex-row space-x-1">
                                    {
                                      (Array.isArray(ex.body) ? ex.body : [ex.body]).map(body =>
                                        <div className="px-2 py-0.5 text-xs rounded bg-slate-300/20 border border-slate-400/20">
                                          {body}
                                        </div>)
                                    }
                                  </div>
                                </div>
                              )}

                              <div className="flex flex-row opacity-50 items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  className="w-3.5 h-3.5 mr-1 opacity-50"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z"
                                    clipRule="evenodd"
                                  />
                                </svg>

                                <div>{exercise.duration} sec</div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="w-full flex flex-col items-center">
                            <div className="font-medium">Rest </div>
                            <div className="flex flex-row opacity-50 items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-3.5 h-3.5 mr-1 opacity-60"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              <div>{exercise.duration} sec</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </SelectableItem>
                  </div>
                );
              }}
            />
          </div>
          <div className="flex flex-row w-full border-t p-2">
            <Button
              title="+ Add an exercise"
              style="secondary"
              onClick={handleAddExercise}
            />
            <div className="mx-1" />
            <Button 
                title="+ Create new Exercise" 
                style="secondary"
                onClick={() => setIsCreateExercisesModalOpen(true)} 
            />
            <div className="mx-1" />
            <Button
              title="+ Add a rest time"
              style="secondary"
              onClick={handleAddRest}
            />
          </div>
        </>
      ) : (
        <div className="m-7 text-slate-500">
          👈 Select a workout to configure it
        </div>
      )}
    </div>
  );
}
