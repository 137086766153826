import React, { useState, useEffect } from "react";
import Filter from "./Filter";

export default function FilterSearchBar({ items, setFilteredItems, levels, body_areas }) {
    const [levelFilter, setLevelFilter] = useState(levels)
    const [bodyFilter, setBodyFilter] = useState(body_areas)
    const [searchQuery, setSearchQuery] = useState("")
    const [isSearchActive, setIsSearchActive] = useState(true);

    useEffect(() => {
        var updatedExercises = items
        if (!isSearchActive) {
            updatedExercises = items
                .filter(item => levelFilter.includes(item?.level?.toLocaleLowerCase()))
                .filter(item => bodyFilter.includes(item?.body?.toLocaleLowerCase()))
        } else {
            updatedExercises = items.filter(item => item?.name?.toLocaleLowerCase().includes(searchQuery))
        }
        setFilteredItems(updatedExercises)
    }, [levelFilter, bodyFilter, searchQuery, isSearchActive])

    return (
        <div className="h-16 p flex justify-center items-center border-b">
            {isSearchActive ? (
                <div className="h-full w-full flex flex-row justify-between divide-x">
                    <button onClick={() => setIsSearchActive(false)} className="text-center px-6 hover:bg-slate-50">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                        </svg>
                    </button>
                    <div className="flex flex-1 inline-flex py-3 px-4">
                        <div class="relative w-full">
                            <div class="absolute inset-y-0 flex items-center ps-3">
                                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                </svg>
                            </div>
                            <input
                                type="search"
                                class="w-full p-2 ps-10 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:outline-0 focus:border-indigo-500 focus:ring-0"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                autoFocus
                            />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="h-full w-full flex flex-row justify-between divide-x">
                    <div className="flex flex-row divide-x py-3 px-4">
                        <Filter title="Level" options={levels} filter={levelFilter} setFilter={setLevelFilter} />
                        <Filter title="Body area" options={body_areas} filter={bodyFilter} setFilter={setBodyFilter} />
                    </div>

                    <button onClick={() => setIsSearchActive(true)} className="text-center px-6 hover:bg-slate-50">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                    </button>
                </div>
            )}
        </div>
    )
}