import React, { useEffect, useState } from "react";

export default function ImageUploader({ title = "Image", image, setImage }) {
  const [preview, setPreview] = useState(null);

  const types = ["image/png", "image/jpeg", "image/jpg"];

  useEffect(() => {
    setPreview(image);
  }, [image]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && types.includes(file.type)) {
      setImage(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  return (
    <div className="mb-4">
      <div className="mb-1">{title}</div>
      <div className="flex w-full border border-2 border-dashed rounded-lg items-center justify-center">
        <label
          className="h-full w-full p-2 flex items-center justify-center cursor-pointer"
          htmlFor="image-upload"
        >
          {preview ? (
            <img src={preview} alt="Image preview" className="h-full" />
          ) : (
            <span className="opacity-50">Upload image</span>
          )}
          <input
            id="image-upload"
            type="file"
            className="hidden"
            onChange={handleImageChange}
            accept="image/*"
          />
        </label>
      </div>
    </div>
  );
}
