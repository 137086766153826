import React from "react";
import SectionHeader from "./common/SectionHeader";
import TextInput from "./settings/TextInput";
import SegmentedControl from "./settings/SegmentedControl";
import ImageUploader from "./settings/ImageUploader";
import Dropdown from "./settings/Dropdown";
import ExerciseSelector from "./settings/ExerciseSelector";
import Button from "./common/Button";

export default function SettingsPanel({
  exercisesLibrary,
  itemToEdit = null,
  handleUpdateSection,
  handleUpdateWorkout,
  handleUpdateExercise,
  handleDeleteSelectedItem,
  handleOpenExercisesModal,
  handleSetImage,
}) {
  const exercise =
    itemToEdit?.type === "exercise" &&
    exercisesLibrary.find((e) => e.id === itemToEdit.id);

  return (
    <div className="flex flex-col h-full w-80 bg-white border-l border-slate-150">
      <SectionHeader title="Settings" />
      {itemToEdit && (
        <>
          <div className="relative flex flex-col flex-1 h-full p-4 overflow-y-auto">
            {itemToEdit.type === "exercise" || itemToEdit.type === "rest" ? (
              <div>
                <SegmentedControl
                  options={["exercise", "rest"]}
                  value={itemToEdit.type}
                  onSelect={(e) => handleUpdateExercise("type", e)}
                />
                {itemToEdit.type === "exercise" && (
                  <ExerciseSelector
                    exercise={exercise}
                    handleOpenExercisesModal={handleOpenExercisesModal}
                  />
                )}
                <TextInput
                  title={"Duration"}
                  value={itemToEdit.duration}
                  type="number"
                  unit="sec"
                  onChange={(e) =>
                    handleUpdateExercise("duration", parseInt(e.target.value))
                  }
                />
              </div>
            ) : itemToEdit.type === "workout" ? (
              <div>
                <ImageUploader
                  image={itemToEdit.image}
                  setImage={handleSetImage}
                />
                <TextInput
                  title={"Name"}
                  placeholder={"Workout name..."}
                  value={itemToEdit.name}
                  onChange={(e) => handleUpdateWorkout("name", e.target.value)}
                />
                <TextInput
                  title={"ID"}
                  placeholder={"Workout id..."}
                  value={itemToEdit.id || ""}
                  onChange={(e) => handleUpdateWorkout("id", e.target.value)}
                />
                <Dropdown
                  title="Level"
                  options={["easy", "intermediate", "advanced"]}
                  selectedOptions={itemToEdit.level}
                  setSelectedOptions={(e) => handleUpdateWorkout("level", e)}
                />
                <Dropdown
                  title="Skill(s)"
                  options={[
                    "endurance",
                    "speed",
                    "recovery",
                    "strength",
                    "agility",
                  ]}
                  multiselect
                  selectedOptions={itemToEdit.skills}
                  setSelectedOptions={(e) => handleUpdateWorkout("skills", e)}
                />
              </div>
            ) : itemToEdit.type === "section" ? (
              <div>
                <TextInput
                  title={"Name"}
                  placeholder={"Section name..."}
                  value={itemToEdit.name}
                  onChange={(e) => handleUpdateSection("name", e.target.value)}
                />
              </div>
            ) : null}
          </div>
          <div className="border-t border-slate-400/20 p-2">
            <Button
              style="secondary_danger"
              title={
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                  <span>Delete {itemToEdit.type || "workout"}</span>
                </>
              }
              onClick={handleDeleteSelectedItem}
            />
          </div>
        </>
      )}
    </div>
  );
}
